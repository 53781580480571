import React from "react";
import { Dialog, DialogContent, Slide, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./_dialogModal.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DialogModal = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        className="accountFrameDialog"
        fullScreen={fullScreen}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogContent className="dialog-modal">
            {props.dialogBody}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogModal;
