import React from "react";
import { FormattedMessage } from "react-intl";
import {
     TextField,
     Divider,
     InputAdornment,
     CircularProgress,
     Button,
   } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ErrorMessage from "../switchAccountError/switchAccountError";
import trimbleLogo from "../../../../../utils/images/trimble-logo.svg";
import "./_switchAccountPopup.scss";
   
   export const SwitchAccountSuccess = (props) => {
     let default_account = props.account_list.find(account => account.is_default);
     return (
       <>
          <div>
           <FormattedMessage defaultMessage="You are switching your account to " />
           <span style={{fontWeight: "700"}}>{default_account?.name}.</span>
         </div>
         <br/>
         <div>
           <FormattedMessage defaultMessage="You will need to sign out and sign back in from all of your active sessions for the account switch to take effect." />
         </div>
         
         <Button
           className="action-button"
           onClick={(e) => props.handleSetDefault(e)}
           color="primary"
           disableElevation
           variant="contained"
           disabled ={props.accSwitch === "pending"}
         >
         <FormattedMessage defaultMessage="Switch" />
         </Button>
         <br/>
         <Button
           className="disable-button"
           onClick={(e) => props.handleClose(e)}
           color="primary"
           disableElevation
           variant="outlined"
           disabled ={props.accSwitch === "pending"}
         >
         <FormattedMessage defaultMessage="Cancel" />
         </Button>
       </>
     );
   };
   export const DisplayAccount = (props) => {
     var account_list = props.account_list && props.account_list.sort((a, b) => b.is_default - a.is_default);
     return (
       <div className="account-list">
         {account_list &&
           account_list.map((account, i) => (
             <div key={i}>
               <div className="account-disp">
                 <div className="acc-name">{account.name}</div>
                 {account.is_default ? (
                   <div className="custom-badge">Default</div>
                 ) : (
                   <div>
                     <a
                       href=""
                       onClick={(e) => props.handleSwitch(e, account.name)}
                     >
                       <FormattedMessage defaultMessage="Set default" />
                     </a>
                   </div>
                 )}
               </div>
               <Divider />
             </div>
           ))}
       </div>
     );
   };
const SwitchAccountPopup = (props) => {
     return (
       <div className="account-modal-body">
         <div className="cancel-popup">
          {props.accSwitch !== "selected" &&
           <a href="" onClick={(e) => props.handleClose(e)}>
             &lt;{" "}
             <span>
               <FormattedMessage defaultMessage="Cancel" />
             </span>
           </a>}
         </div>
         <img src={trimbleLogo} className="trimble-logo" alt="trimbel_logo" />
         <div className="account-switch-title">
           <FormattedMessage defaultMessage="Switch account" />
         </div>
         <br />
         {props.error && 
         <ErrorMessage 
         error = {props.error}/>}

         {props.accSwitch !== "" ? (
             <SwitchAccountSuccess
               accSwitch = {props.accSwitch}
               handleSetDefault={props.handleSetDefault}
               account_list={props.filteredAccList}
               handleClose={props.handleClose}
             />
         ) : (
           <>
             {props.acc_list && props.acc_list.length >= 0 ? (
               <div>
                 <div>You have {props.acc_list.length} accounts.</div>
                 <br />
                 <TextField
                   placeholder="Filter by account name"
                   className="search-text-field"
                   value={props.inputValue}
                   onChange={(e) => props.handleInputChange(e)}
                   variant="outlined"
                   InputProps={{
                     startAdornment: (
                       <InputAdornment position="start">
                         <FilterListIcon />
                       </InputAdornment>
                     ),
                   }}
                 />
   
                 <DisplayAccount
                   account_list={props.filteredAccList}
                   handleSwitch={props.handleSwitch}
                 />
               </div>
             ) : (
               <div className="progress-spin">
                 <CircularProgress size="2rem" />
               </div>
             )}
           </>
         )}
       </div>
     );
   };

   export default SwitchAccountPopup;