import React from "react";
import { Alert} from "@material-ui/lab";
import {Typography } from '@material-ui/core';
import { FormattedMessage } from "react-intl";

const ErrorMessage = (props) => {
  return (
    <>
      <Alert severity="error">
        <Typography variant="subtitle1">
          {props.error === "account_not_found" ?
          <FormattedMessage defaultMessage= "Account not found. Please contact your administrator." /> 
          : props.error === "internal_server_error" ?
          <FormattedMessage defaultMessage= "An error has occurred communicating with the server. Please try again." />
          : props.error === "Forbidden" ?
          <FormattedMessage defaultMessage= "you do not have permission to perform this operation. Please contact your administrator." /> 
          :
          <FormattedMessage defaultMessage= "Error occured. Please try again later." />}
        </Typography>
      </Alert>
    </>
  );
};

export default ErrorMessage;
