export const flags = {
     show_passkey : true,
     show_passkey_as_mfa: true, //for all types of users
     show_reset_totp: true,
     show_reset_sms: true,
     show_state_dropdown: true
}

export const configs = {
     totp_reset_end_date : "Jan 31, 2025",
     sms_mfa_end_date: "Apr 02, 2025"
}