import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "./_switchAccount.scss";
import FieldSkeleton from "../../../../components/skeleton/FieldSkeleton";
import { getAccount, setDefaultAccount } from "./switchAccountService";
import DialogModal from "../../../../components/dialogModal/dialogModal";
import SwitchAccountPopup from "./switchAccountPopup/switchAccountPopup";

const SwitchAccount = (props) => {
  const [open, setOpen] = useState(false);
  const [accList, setAccList] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredAccList, setFilteredAccList] = useState([]);
  const [accSwitch, setAccSwitch] = useState("");
  const [error, setError] = useState('');

  const handleClose = (event) => {
    event.preventDefault();
    setOpen(false);
  };

  const handleOpen = (event) => {
    event.preventDefault();
    setInputValue("");
    setAccList("");
    setOpen(true);
    setAccSwitch("");
    setError("");

    getAccount(props.uuid, props.endpoints)
      .then((response) => {
        if(response?.status === 200){
          let account_list = response?.data?.accountList;
          setAccList(account_list);
          setFilteredAccList(account_list);
        }else{
          if(response?.status === 401) 
            props.sign_out();
          else 
            setError(response?.data?.error ?? "default");
      }
    })
    .catch((error) => {
      setError("default");
    });
  };
  const handleInputChange = (event) => {
    event.preventDefault();
    let input_val = event.target.value;
    setInputValue(event.target.value);
    let updated_acc_list =
      input_val.length >= 2
        ? accList &&
          accList.filter((acc) => acc.name.toLowerCase().includes(input_val))
        : accList;
    setFilteredAccList(updated_acc_list);
  };

  const handleSwitch = (event, acc_name) => {
    event.preventDefault();
    let updated_acc_list =
    accList &&
    accList.map((acc) => ({
      ...acc,
      is_default: acc.name === acc_name,
    }));
    setFilteredAccList(updated_acc_list);
    setAccSwitch("selected");
  }

  const handleSetDefault = (event) => {
    event.preventDefault();
    setAccSwitch("pending");
    const default_acc_name = filteredAccList.find(account => account.is_default);
    setError("");
    let post_data = {
      default_account_name: default_acc_name.name,
    };
    setDefaultAccount(props.uuid, props.endpoints, post_data)
      .then((response) => {
        if(response && response.status === 200){
          setInputValue("");
          handleClose(event);
          props.reload_data();
        }else{
          setAccSwitch("failure");
          if(response?.status === 401) 
            props.sign_out();
          else
            setError(response?.data?.error ?? "default");
       }
    })
    .catch((error) => {
      setAccSwitch("failure");
      setError("default");
    });
  };
  return (
    <div className="account-holder">
      {props.hasIdentity ? (
        <>
          <h4>
            <FormattedMessage defaultMessage="Switch your account" />
          </h4>
          <div className="acc-text">
            <FormattedMessage defaultMessage="Easily switch between accounts to access specific products and services associated with your accounts." />
          </div>
          <div className="list-view-acc">
            <div className="acc-name">{props.default_account}</div>
            <div className="custom-badge-grey">
              <FormattedMessage defaultMessage="Default" />
            </div>
          </div>
          <div className="link-holder">
            <div className="link" onClick={(e) => handleOpen(e)}>
              <FormattedMessage defaultMessage="Switch account" />
            </div>
          </div>
        </>
      ) : (
        <FieldSkeleton />
      )}
      <br />
      <DialogModal
        open={open}
        handleClose={handleClose}
        dialogBody={
          <SwitchAccountPopup
            acc_list={accList}
            filteredAccList={filteredAccList}
            inputValue={inputValue}
            accSwitch={accSwitch}
            error={error}
            handleInputChange={handleInputChange}
            handleSetDefault={handleSetDefault}
            handleClose={handleClose}
            handleSwitch={handleSwitch}
          />
        }
      />
    </div>
  );
};

export default SwitchAccount;
