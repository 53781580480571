import IdentityService, { IdentityAPI } from '../../../../services/identityService';

export const getAccount = async (uuid, endpoints) => {
     return IdentityService.request({
          url: IdentityAPI.parseRequestURL(IdentityAPI.get_my_details+'/accounts', {uuid: uuid}),
          method: 'GET',
          baseURL: endpoints.identityAPI
        })
}

export const setDefaultAccount = async (uuid, endpoints, post_data ) => {
     return IdentityService.request({
          url: IdentityAPI.parseRequestURL(IdentityAPI.get_my_details+'/accounts', {uuid: uuid}),
          method: 'POST',
          baseURL: endpoints.identityAPI,
          data: post_data
        })
}